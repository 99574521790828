// this component shows the geojson or gpx trail on map.
// Trail details (from the GPX file) are shown as custom control on the map
import React, { useContext, useEffect, useMemo } from 'react';
import { MapContainer, useMap, Marker, Tooltip } from 'react-leaflet';
import { LocationControl, ChangeCRS, crsFinland, ElevationControl } from './mapcontrols';
import L from 'leaflet';
import "proj4leaflet";
import "leaflet-gpx";


// import '@raruto/leaflet-elevation/src/index.js';
// import '@raruto/leaflet-elevation/src/index.css';
import {startIcon, endIcon, defaultIcon} from './mapicons';
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
import { MapLayersContract, MapLayersMMLOpenDetails  } from './maplayers';
import { UserContext } from '../user/user-context';
import { markerIcon} from './markers';
import { fireIcon } from './mapicons';
// import icons
import icon_start from 'leaflet-gpx/pin-icon-start.png';
import icon_end from 'leaflet-gpx/pin-icon-end.png';
import icon_shadow from 'leaflet-gpx/pin-shadow.png';



const TrailMap = React.forwardRef(({center, gJSON, gpxfile, trailObjects, showElevation }, ref) => {
      const { status } = useContext(UserContext);

    
    // Show GPX data on map. It uses Leaflet GPX -library
    function ShowGPX() {

      const map = useMap();
      
      // GPX file is shown every time the gpxfile changes
      useEffect(() =>{
        if (gpxfile) {
                try {
                  new L.GPX(gpxfile, {async: true, marker_options: {
                    startIconUrl: icon_start,
                    endIconUrl: icon_end,
                    shadowUrl: icon_shadow}
                  }).on('loaded',function(e) {                                             // When the GPX data is loaded from gpx file, execute callback function
                    map.fitBounds(e.target.getBounds());                                    // set map bounds to GPS track area
                    // let dist =  e.target.get_distance()/1000;                               // total distance
                    // let time = e.target.get_total_time()/1000/60;                           // total time
                    // let ele = e.target.get_elevation_gain();                                // total elevation
                    // let name = e.target.get_name();                                         // name of gpx track
                    // let date = e.target.get_start_time().toLocaleDateString();              // start date        
                    
                  }).on('error', function(e) {
                    console.log('Error loading file: ' + e.err);
                  }).addTo(map);                                                          // Show GPX trail on map
                }
                catch(err) {
                  alert("Ei validi GPX-tiedosto!")
                }                                                         
          }
           
          return () => {
            // cancel the request before component unmounts
            // console.log("cancel load")
          };

        },[map]);

          return null;
      } 

        function ShowGeoJSON() { 

          // show geojson track on map
            const map = useMap();
            
            if (gJSON) {
              // console.log ("Showing geojson: ", gJSON);
              try {
                if (gJSON.features.length === 1 && gJSON.features[0].geometry.type === "Point") {
                  // console.log ("Showing Point: ", gJSON.features[0].geometry.coordinates);
                  const pointposition =[gJSON.features[0].geometry.coordinates[1], gJSON.features[0].geometry.coordinates[0]];
                  L.marker(pointposition, {icon: defaultIcon}).addTo(map); 
                  map.flyTo(pointposition, 10);
                  return null;
                }
                const startposition =[gJSON.features[0].geometry.coordinates[0][1], gJSON.features[0].geometry.coordinates[0][0]] ;
                const length = gJSON.features[0].geometry.coordinates.length;
                const endposition = [gJSON.features[0].geometry.coordinates[length-1][1],gJSON.features[0].geometry.coordinates[length-1][0]] ;
                let feature = L.geoJSON(gJSON, {style: {color: "purple", weight: 5}}).addTo(map);        // show the trail on the map

                // L.marker(startposition, {icon: startIcon}).addTo(map); 
                // L.marker(endposition, {icon: endIcon}).addTo(map);   
                map.fitBounds(feature.getBounds());               // set the trail in the center of the map

              } catch(err) {
                console.log("Error in adding JSON to map:", err);
              }
            } else {
              // console.log("No geoJSON to draw");
            }
          
            return null
          } 
    
    const showTrailMap = useMemo(() => (
        <MapContainer
            style={{height: '60vh'}}
            center={center}
            continuousWorld= {true} 
            worldCopyJump= {false}
            scrollWheelZoom={false}
            crs = {crsFinland}
            zoom={6}
        >
       
        {status?<MapLayersContract/>:<MapLayersMMLOpenDetails/>}

          {(trailObjects) && trailObjects.map((place) => 
            <Marker 
              key={place.sportsPlaceId}
              data = {place.name}
              icon = {markerIcon(place)}
              
              position={[
                  place.location.coordinates.wgs84.lat,
                  place.location.coordinates.wgs84.lon
                ]}
            >
                <Tooltip>
                    {place.name}
                </Tooltip>
            </Marker>
          )}

          <ChangeCRS />
          <LocationControl />
          <FullscreenControl />

          <ShowGeoJSON/> 
          <ShowGPX />  
          {showElevation && <ElevationControl gpxfile = {gpxfile} gjson={gJSON} />}
      </MapContainer>

    ), [gJSON, gpxfile, trailObjects])

    // Show map with GPX trail or GeoJSON
    return (
      <div ref={ref}>
        {showTrailMap}
      </div>
    );
  });

  export default TrailMap;