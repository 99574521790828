import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Header } from 'semantic-ui-react';
import axios from 'axios';
import TrailMap from '../maps/trailmap';
import './styles.css';

export default function TrailDetails({trailID}) {

  const location = {lat: 61.48, lng: 23.78};
 
  const [geoJSON, setJSON] = useState(null);
  const [trailData, setTrailData] = useState({name: "Reitin nimi", address: "", city: "", info: "", pituus: ""});
  const [trailObjects, setTrailObjects] = useState([]);
  const [showElevation, setShowElevation] = useState(false);

//  let js2xmlparser = require('js2xmlparser');

const baseurl = 'https://lipas.fi/rest/sports-places/?fields=name&fields=type.typeCode&fields=location.city.name&fields=location.coordinates.wgs84&fields=www';

function getapiurl(traillocation)  {

  // Hae laavupaikat, nuotiopaikat ja reittiopasteet 10 km säteellä
  const closeToDist = "&closeToDistanceKm=10";
  const typeCode =  "&typeCodes=301&typeCodes=206&typeCodes=207";
  const closeToLat = `&closeToLat=${traillocation.lat}`;
  const closeToLon = `&closeToLon=${traillocation.lon}`;
  
  return(baseurl + closeToDist + closeToLat + closeToLon + typeCode);
}
  
  
  useEffect(() => {
    // get trail data from server
    const apiUrl = `https://api.lipas.fi/v2/sports-sites/${trailID}`;
      // console.log("Getting trail details for trailID: ", trailID);
      axios.get(apiUrl)
      .then (res => {
        const result = res.data.location.geometries;
        setShowElevation(result.features[0].geometry.coordinates[0].length === 3);

        let linkaddress = "";
        setJSON(result);
        const geometry = result.features[0].geometry.type;
        // console.log("geometry type ", geometry);
        if (geometry === "Point") {
          // console.log ("res.data: ", res.data);
          setTrailData({ name: res.data.name, address: res.data.location.address, city: res.data.location.city.name, info: "", pituus: "", link: res.data.www});
        } else {
          // check if the link begins with http(s)://
          if (res.data.www) {
            const str = res.data.www.split(":");
            if (str.length > 0 ) {
              if (str[0].includes("http")) {
                // console.log("Found http from link address");
                linkaddress = res.data.www;
              } else {
                // console.log("Did not find http from link address");
                linkaddress = "http://" + res.data.www;
              }
              
            } else {
              // console.log("Link did not include https://")
              linkaddress = "http://" + res.data.www;
            }
            
          }

          
          setTrailData({ name: res.data.name, address: res.data.location.address, city: res.data.location["postal-office"], info: res.data.comment, pituus: res.data.properties["route-length-km"], link: linkaddress});
          

          // get trail objects (laavut, nuotiopaikat)
          // const trailLocation = {lat: res.data.location.coordinates.wgs84.lat , lon: res.data.location.coordinates.wgs84.lon };
          // this does not work with API v2
          // const trailobjectsurl = getapiurl(trailLocation);
          // axios.get(trailobjectsurl)
          //   .then (res => {
          //     setTrailObjects(res.data);
          //   })
          //   .catch(console.error)
        }
      })
      .catch(console.error);

  }, [trailID]);

  return (
    <Container fluid className="routeModal">
      <Row>
        <Col >
        <div className="infobox">
        <Row>
            <Header as='h2'>{trailData.name}</Header>
          </Row>
          <Row>
            <Col xs="2"><Header as='h4'>Osoite:</Header></Col>
            <Col>{trailData.address}, {trailData.city}</Col> 
          </Row> 
          {(trailData.info !== "") && 
            <Row>
              <Col xs="2"><Header as='h4'>Kuvaus: </Header> </Col>
              <Col>{trailData.info}</Col>
            </Row>
          }
          {(trailData.pituus !== "") && 
            <Row>
              <Col xs="2"><Header as='h4'>Pituus: </Header> </Col>
              <Col>{trailData.pituus} km <br /></Col>
            </Row>}

            <Row>
              
              <Col xs="2"> {trailData.link ? <a href={trailData.link}>Lisätietoja</a> : '' }</Col>
                         
            </Row>
          
          <Row>
            <Col></Col>
          </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <TrailMap center = {location} gJSON = {geoJSON} trailObjects = {trailObjects} showElevation = {showElevation}/>
        </Col>
      </Row>
    </Container>
  );
}




