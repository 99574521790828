import { useEffect, useState, useCallback } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import "proj4leaflet";
import Locate from "leaflet.locatecontrol";

import '@raruto/leaflet-elevation/src/index.css';
import {Distance}  from '@raruto/leaflet-elevation/src/handlers/distance.js';
import {Altitude}  from '@raruto/leaflet-elevation/src/handlers/altitude.js';

// import * as d3 from 'd3';

import * as toGeoJSON from '@tmcw/togeojson';


import * as _ from '@raruto/leaflet-elevation/src/utils';
import '@raruto/leaflet-elevation/dist/leaflet-elevation'; 


export function ElevationControl ({gpxfile, gjson}) {
      
    const map = useMap();
    

    const elevation_options = {
      
      theme: "lightblue-theme",   
      detached: true,
      elevationDiv: "#elevation-div",
      autohide: false,
      collapsed: false,
      position: "topright",
      closeBtn: true,
      followMarker: true,
      autofitBounds: true,
      imperial: false,
      reverseCoords: false,
      acceleration: false,
      slope: false,
      speed: false,
      altitude: true,
      time: true,
      distance: true,
      summary: false,
      downloadLink: false,
      ruler: true,
      legend: true,
      almostOver: true,
      distanceMarkers: true,
      edgeScale: false,
      hotline: true,
      timestamps: true,
      waypoints: true,
      preferCanvas: true,
      handlers:[Distance, Altitude],
      srcFolder: 'https://unpkg.com/@raruto/leaflet-elevation/src/'
    };
    
    useEffect(() => {
      if (!map) return;
      if (!gpxfile && !gjson) return;
        // Monkey patch the import function -> https://leafletjs.com/examples/extending/extending-1-classes.html#lclassinclude
            L.Control.Elevation.include({

                import: function(src, condition) {
                    if (Array.isArray(src)) {
                        return Promise.all(src.map(m => this.import(m)));
                    }
                    switch(src) {
                        case this.__D3:          condition = typeof d3 !== 'object'; break;
                        case this.__TOGEOJSON:   condition = typeof toGeoJSON !== 'object'; break;
                        case this.__LGEOMUTIL:   condition = typeof L.GeometryUtil !== 'object'; break;
                        case this.__LALMOSTOVER: condition = typeof L.Handler.AlmostOver  !== 'function'; break;
                        case this.__LDISTANCEM:  condition = typeof L.DistanceMarkers  !== 'function'; break;
                        case this.__LEDGESCALE:  condition = typeof L.Control.EdgeScale !== 'function'; break;
                        case this.__LHOTLINE:    condition = typeof L.Hotline  !== 'function'; break;
                    }

                    // Add the ignore comment for webpack
                    return condition !== false ? import(/* webpackIgnore: true */ _.resolveURL(src, this.options.srcFolder)) : Promise.resolve();
                }

            });

        // ends here
        const elevationControl = L.control.elevation(elevation_options).addTo(map);

        try {
            if (gpxfile) {
              elevationControl.load(gpxfile);
            }
            else if (gjson) {
              const dataToLoad = JSON.stringify(gjson,undefined,2);
              elevationControl.load(dataToLoad);
            }
        } catch(err) {
            console.log("could not show elevation, error: ", err);
        }

        return() => {
            map.removeControl(elevationControl);
          }

      
    }, [map, gpxfile, gjson]);

  }

export function LocationControl() {

    const map = useMap();
  
    useEffect(() => {
      const lc = new Locate({keepCurrentZoomLevel: true});
      lc.addTo(map);
      // lc.start();
      
    }, [map]);
  
    return null;
  }
  
  // CRS settings for Finland
    const resolutions =  [8192, 4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25, 0.125, 0.0625, 0.03125, 0.015625]
      
   export const crsFinland = new L.Proj.CRS(
        'EPSG:3067',
        '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
        {
          resolutions,
          origin: [-548576,8388608]
        }
      );
      
  L.Map.prototype.setCrs = function(newCrs) {
    this.options.crs = newCrs;
}
  
export function ChangeCRS() {
    const [currentBaseLayer, setCurrentBaseLayer] = useState("Taustakartta");

    const map = useMap();
   
    const onBaseLayerChange = useCallback((layer) => {
                
        let newZoom = map.getZoom();
        // console.log("Base layer changed");
        if(layer.name === 'Open Street map')
            {
                // console.log ("You selected OSM!");
                map.setCrs(L.CRS.EPSG3857);       
                newZoom = map.getZoom() + 4;                           //Change from any to OSM -> Zoom level is increased
            }
        else {
            map.setCrs(crsFinland);
            if (currentBaseLayer === 'Open Street map') {
                // console.log("Changing away from OSM");
                newZoom = map.getZoom() - 4;                           // Change from OSM to any -> Zoom level is decreased
            }
        }
        // console.log("Callback: Changing layer from ", currentBaseLayer," to ", layer.name );
        setCurrentBaseLayer(layer.name);
        // console.log("Current zoom: ", map.getZoom(), " newZoom: ", newZoom);
        map.setView(map.getCenter(),newZoom);

      }, [map, currentBaseLayer])

    useEffect(()=> {
        // console.log("Use Effect, currentBaseLayer:", currentBaseLayer);
        map.on('baselayerchange', onBaseLayerChange);

        return () => {
            map.off('baselayerchange', onBaseLayerChange);
        }
    }, [map, onBaseLayerChange,currentBaseLayer]);
  
    return null;
}
  


  

